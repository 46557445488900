import { clsx } from 'clsx';
import { Link } from './Link';

export function Text({ className, ...props }: React.ComponentPropsWithoutRef<'p'>) {
  return (
    <p
      {...props}
      data-slot="text"
      className={clsx(className, 'text-gray-6 dark:text-gray-4 text-base/6 sm:text-sm/6')}
    />
  );
}

export function TextLink({ className, ...props }: React.ComponentPropsWithoutRef<typeof Link>) {
  return (
    <Link
      {...props}
      className={clsx(
        className,
        'text-gray-12 decoration-gray-12/50 data-[hover]:decoration-gray-12 underline dark:text-white dark:decoration-white/50 dark:data-[hover]:decoration-white',
      )}
    />
  );
}

export function Strong({ className, ...props }: React.ComponentPropsWithoutRef<'strong'>) {
  return <strong {...props} className={clsx(className, 'text-gray-12 font-medium dark:text-white')} />;
}

export function Code({ className, ...props }: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        'border-gray-12/10 bg-gray-12/[2.5%] text-gray-12 rounded border px-0.5 text-sm font-medium sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white',
        className,
      )}
    />
  );
}

export function CodeBlock({ className, ...props }: React.ComponentPropsWithoutRef<'code'>) {
  return (
    <code
      {...props}
      className={clsx(
        className,
        'border-gray-12/10 bg-gray-12/[2.5%] text-gray-12 block whitespace-pre-wrap rounded border p-2 text-sm font-medium sm:text-[0.8125rem] dark:border-white/20 dark:bg-white/5 dark:text-white',
      )}
    />
  );
}
